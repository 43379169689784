*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: inherit;
}

body {
	margin: 0;
	min-width: 320px;
	min-height: 100vh;
	min-height: 100dvh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-decoration-skip-ink: none;
	font-family: "monsterfriendforerusbylya", "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;

	/* background: radial-gradient(circle at 100% 50%, #6868ff, transparent 60%), linear-gradient(45deg, #7e6ed3, #95cdff); */
	--bcsize: 5px;
	--bssize: 3.5px;
	--background_main:
		linear-gradient(0deg, transparent, #ffdb6c),
		radial-gradient(circle at var(--bcsize) var(--bcsize), #fff6a7 var(--bssize), transparent var(--bssize)) 0px 0px / calc(var(--bcsize) * 4) calc(var(--bcsize) * 4),
		radial-gradient(circle at calc(var(--bcsize) * 3) calc(var(--bcsize) * 3), #fff6a7 var(--bssize), transparent var(--bssize)) 0px 0px / calc(var(--bcsize) * 4) calc(var(--bcsize) * 4),
		linear-gradient(0deg, #ffe87e, #ffdb6c);
	--background: var(--background_main);
	--color: black;
	--c-back: #ffe87e;
	--c-primary: #f4ad51;
	--c-secondary: #3f0000;
	--c-text: #fff2b5;
	background: var(--background);
	color: var(--color);
}
body:has(.calmBack) {
	--background: linear-gradient(0deg, #ffe87e, #ffdb6c);
}
body:has(.forStaff) {
	font-family: "ZeroCool", "PT Sans", Arial;
	--background: linear-gradient(0deg, #00093b, #001b3b);
	--color: white;
	--c-back: #194659;
	--c-primary: #194659;
	--c-secondary: #7fffd4;
	--c-text: #fff;
}
body:has(.forDev) {
	font-family: "PT Sans", Arial;
	--background: #1e1e1e;
	--color: #d4d4d4;
	--c-back: #1e1e1e;
	--c-primary: #1e1e1e;
	--c-secondary: #d4d4d4;
	--c-text: #d4d4d4;
}
.forDev pre {
	background-color: var(--background);
	color: var(--color);
	width: max-content;
}
.calmBack {
	position: fixed;
}

pre,
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.root {
	min-height: 100vh;
	min-height: 100dvh;
	display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
}
h1 {
	text-align: center;
}

button {
	cursor: pointer;
}
button:disabled {
	cursor: default;
}

.clearBtn {
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	color: inherit;
	font: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	background-color: gray;
}